@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.customScrollBar::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}
.customScrollBar::-webkit-scrollbar-thumb {
  background: #07939bcc;
  border-radius: 8px;
}
.active {
  background-color: #07939bcc;
}

.table-zebra tbody tr:nth-child(odd) th,
.table-zebra tbody tr:nth-child(odd) td {
  --tw-bg-opacity: 1;
  background-color: #f7f8f9;
}
.table-zebra tbody tr:nth-child(even) th,
.table-zebra tbody tr:nth-child(even) td {
  --tw-bg-opacity: 1;
  background-color: #f7f8f9;
}

.labelCheck {
  display: flex;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  padding-left: 0.25rem 4px;
  padding-right: 0.25rem 4px;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
}
.btm-nav > * .labelCheck {
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
}
.labelCheck a:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
}

.table:where(:not(.table-zebra))
  :where(thead, tbody, tfoot)
  :where(tr:not(:last-child) :where(th, td)) {
  border-bottom-width: 20px;
  --tw-border-opacity: 1;
  border-color: white;
}
.table :where(thead, tfoot) :where(th, td) {
  --tw-bg-opacity: 1;
  background-color: transparent;
  color: rgb(0 0 0 / 0.85);
  font-size: 14px;
  line-height: 1rem /* 16px */;
  font-weight: 600;
  text-transform: uppercase;
}
.table :where(tbody th, tbody td) {
  --tw-bg-opacity: 1;
  background-color: #f7f8f9;
  color: rgb(0 0 0 / 0.85);
  font-size: 14px;
  font-weight: 500;
  /* text-transform: capitalize; */
}

.range {
  height: 1.5rem /* 24px */;
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  --range-shdw: var(--bc);
  overflow: hidden;
  background-color: transparent;
  border-radius: var(--rounded-box, 1rem);
}

.range::-webkit-slider-runnable-track {
  height: 0.5rem /* 8px */;
  width: 100%;
  border-radius: var(--rounded-box, 1rem);
  background-color: rgb(0 0 0 / 0.1);
}
.range::-webkit-slider-thumb {
  background-color: white;
  position: relative;
  height: 1.5rem /* 24px */;
  width: 1.5rem /* 24px */;
  border-style: none;
  border-radius: var(--rounded-box, 1rem);
  -webkit-appearance: none;
  appearance: none;
  top: 50%;
  color: hsl(var(--range-shdw));
  transform: translateY(-50%);
  --filler-size: 100rem /* 1600px */;
  --filler-offset: 0.6rem /* 9.6px */;
  box-shadow: 0 0 0 3px hsl(var(--range-shdw)) inset, var(--focus-shadow, 0 0),
    calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
}
.range-accent {
  --range-shdw: var(--a);
}

/* card for branches */
.card-body {
  display: flex;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  flex-direction: column;
  padding: 0;
  gap: 0.5rem /* 8px */;
}
.card-body :where(p) {
  flex-grow: 1;
}
.card.image-full > .card-body {
  position: relative;
  z-index: 20;
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
}
.card.compact .card-body {
  padding: 10rem /* 16px */;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
}
.card-compact .card-body {
  padding: 10rem /* 16px */;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
}
.card-normal .card-body {
  padding: 10rem;
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
}
